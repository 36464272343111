<template>
  <div>
    <div class="category-header">
      <div class="category-header-item">
        <div></div>
      </div>
      <div class="category-header-item category-header-item-title" v-on:click="collapsed = !collapsed">
        <p ref="title" class="category-header-title" :class="{ collapsed: collapsed, expanded: !collapsed }">{{ category.title }}{{ ticketCities === null ? '' : (loading ? ' (...)' : ` (${ticketsCount})`) }}</p>
      </div>
      <div class="category-header-item">
        <ImageButton :disabled="loading" class="btn-refresh-category" :src="require('@/assets/images/refresh-blue-24dp.svg')" title="Refresh list" v-on:click="getTickets"/>
      </div>
    </div>
    <ul ref="ticketsList" class="tickets-list">
      <li v-if="loading" class="li-hint">
        <Spinner size="24px"/>
      </li>
      <li v-if="error" class="li-hint">
        Error loading tickets
      </li>
      <li v-if="!loading && !error && ticketCities !== null && Object.keys(ticketCities).length === 0" class="li-hint">
        No pending tickets
      </li>
      <li v-for="city in ticketCities" :key="city.title">
        <div class="tickets-list-city-title collapsed" v-on:click="toggleCity">{{ city.title }} ({{ city.tickets.length }})</div>
        <ul class="tickets-list-city">
          <li v-for="ticket in city.tickets" :key="ticket.id" :ref="`ticket-${ticket.id}`" class="ticket" :class="{selected: selectedTicket === ticket}" v-on:click="selectTicket(ticket)">
            <div class="ticket-title">{{ ticket.title }}</div>
            <div class="ticket-address">{{ ticket.address }}</div>
            <div class="ticket-updated-at">{{ ticket.updated_at }}</div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { eventBus, events } from '@/constants/eventBus';
import MapDataHelper from '@/utils/mapDataHelper';
import ImageButton from '@/components/ImageButton';
import Spinner from '@/components/Spinner';

export default {
  name: 'TicketsCategory',
  components: { Spinner, ImageButton },
  props: {
    category: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      collapsed: true,
      ticketCities: null,
    };
  },
  computed: {
    ...mapGetters('editor', ['selectedTicket']),
    ticketsCount() {
      return this.ticketCities === null ? 0 : Object.values(this.ticketCities).reduce((acc, c) => acc + c.tickets.length, 0);
    },
  },
  created() {
    eventBus.$on(events.ticketProcessed, this.onTicketProcessed);
    eventBus.$on(events.segmentDeleted, this.onSegmentDeleted);
    eventBus.$on(events.fuelStationDeleted, this.onFuelStationDeleted);
  },
  watch: {
    collapsed(collapsed) {
      if (collapsed) {
        this.$refs.ticketsList.style.height = '0';
        this.$refs.ticketsList.style.padding = '0';
      } else {
        this.$refs.ticketsList.style.height = 'auto';
        this.$refs.ticketsList.style.padding = '8px 0';
        if (this.ticketCities === null) {
          this.getTickets();
        }
      }
    },
  },
  methods: {
    getTickets() {
      if (this.loading) return;

      this.loading = true;
      this.error = null;
      this.collapsed = false;
      this.ticketCities = {};
      MapDataHelper.getPendingTickets(this.category.id, this.onGotTickets, this.onGetTicketsError);
    },
    onGotTickets(request, result) {
      this.loading = false;
      this.ticketCities = result.tickets;
    },
    onGetTicketsError() {
      this.loading = false;
      this.error = true;
    },
    toggleCity(event) {
      if (event.target.classList.contains('collapsed')) {
        event.target.classList.remove('collapsed');
        event.target.classList.add('expanded');
        event.target.nextElementSibling.style.height = `${event.target.nextElementSibling.scrollHeight}px`;
      } else {
        event.target.classList.remove('expanded');
        event.target.classList.add('collapsed');
        event.target.nextElementSibling.style.height = '0px';
      }
    },
    selectTicket(ticket) {
      this.$store.dispatch('editor/selectedTicket', ticket);
      eventBus.$emit(events.jumpToRequested, [ticket.lng, ticket.lat]);
    },
    onTicketProcessed(ticketId) {
      const ticket = this.findTicketById(ticketId);
      if (ticket !== null) {
        this.removeTicketFromList(ticket);
      }
    },
    onSegmentDeleted(segmentId) {
      for (const ticket of this.findTicketsByProperty('segment_id', segmentId)) {
        this.removeTicketFromList(ticket);
      }
    },
    onFuelStationDeleted(stationId) {
      for (const ticket of this.findTicketsByProperty('fuel_station_id', stationId)) {
        this.removeTicketFromList(ticket);
      }
    },
    removeTicketFromList(ticket) {
      const updateLi = this.$refs[`ticket-${ticket.id}`][0];
      const cityUl = updateLi.parentNode;
      if (cityUl.style.height && cityUl.style.height !== '0px') {
        // Force resize of city list to proper height by subtracting the li total height (height + margins) from it
        const liStyle = window.getComputedStyle(updateLi);
        const liHeight = updateLi.scrollHeight + parseInt(liStyle.getPropertyValue('margin-top'), 10) + parseInt(liStyle.getPropertyValue('margin-bottom'), 10);
        cityUl.style.height = `${cityUl.scrollHeight - liHeight}px`;
      }

      const city = this.ticketCities[ticket.city_id];
      city.tickets = city.tickets.filter((el) => el !== ticket);
      if (city.tickets.length === 0) {
        delete this.ticketCities[ticket.city_id];
      }

      if (Object.entries(this.ticketCities).length === 0) {
        this.getTickets();
      }
    },
    findTicketById(ticketId) {
      if (this.ticketCities === null) return null;

      for (const [, city] of Object.entries(this.ticketCities)) {
        for (const ticket of city.tickets) {
          if (ticket.id === ticketId) {
            return ticket;
          }
        }
      }

      return null;
    },
    findTicketsByProperty(prop, value) {
      if (this.ticketCities === null) {
        return [];
      }

      const tickets = [];
      for (const [, city] of Object.entries(this.ticketCities)) {
        for (const ticket of city.tickets) {
          if (ticket[prop] === value) {
            tickets.push(ticket);
          }
        }
      }

      return tickets;
    },
  },
};
</script>

<style scoped>

.category-header {
  background-color: white;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212529;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #CED6DE;
}

.category-header-item {
  display: flex;
  justify-content: center;
  padding: 0 8px;
  text-transform: capitalize;
}

.category-header-item:first-child {
  flex: 1;
}

.category-header-item:last-child {
  flex: 1;
}

.category-header-item:first-child > * {
  margin-right: auto;
}

.category-header-item:last-child > * {
  margin-left: auto;
}

.category-header-item.category-header-item-title {
  cursor: pointer;
}

.category-header-title {
  display: flex;
}

/*noinspection CssUnusedSymbol*/
.category-header-title.collapsed:before, .category-header-title.expanded:before {
  width: 24px;
  height: 18px;
  position: sticky;
  display: flex;
  background-image: url('~@/assets/images/expand_more-black-24dp.svg');
  background-position: center;
  content: " ";
  transform-origin: center;
  animation: expand-caret 0.2s ease-in;
  animation-fill-mode: forwards;
}

.category-header-title.collapsed:before {
  animation: collapse-caret 0.2s ease-in;
  animation-fill-mode: forwards;
}

.btn-refresh-category {
  margin-right: 8px;
}

.tickets-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 0;
}

.li-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
}

.ticket {
  display: block;
  cursor: pointer;
  background: white;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  margin: 0 16px 12px 16px;
}

.ticket:hover {
  background: #E6F3FA;
}

/*noinspection CssUnusedSymbol*/
.ticket.selected {
  background: #E6F3FA;
  border: 1px solid #1C9BE6;
}

.ticket-title {
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #1c9be6;
}

.ticket-address {
  margin: 4px 0 0 0;
  font-size: 16px;
  line-height: 18px;
  color: #212529;
}

.ticket-updated-at {
  margin: 4px 0 0 0;
  font-size: 14px;
  line-height: 17px;
  color: #8293A3;
}

.tickets-list-city-title {
  padding: 8px 8px 8px 26px;
  color: #1C9BE6;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  display: flex;
}

/*noinspection CssUnusedSymbol*/
.tickets-list-city-title.collapsed:before, .tickets-list-city-title.expanded:before {
  width: 16px;
  height: 16px;
  position: sticky;
  display: flex;
  margin-left: -18px;
  background-image: url('~@/assets/images/expand_more-blue-18dp.svg');
  background-position: center;
  content: " ";
  transform-origin: center;
  animation: expand-caret 0.2s ease-in;
  animation-fill-mode: forwards;
}

.tickets-list-city-title.collapsed:before {
  animation: collapse-caret 0.2s ease-in;
  animation-fill-mode: forwards;
}

.tickets-list-city {
  height: 0;
  padding: 0;
  overflow: hidden;
}

@keyframes expand-caret {
  0% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes collapse-caret {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

</style>
