<template>
  <div id="map"></div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';
import { mapGetters } from 'vuex';

import { eventBus, events } from '@/constants/eventBus';
import MapboxHelper from '@/utils/mapboxHelper';
import StreetViewControl from '@/utils/streetViewControl';
import SettingsControl from '@/utils/settingsControl';
import SatelliteLayerControl from '@/utils/satelliteLayerControl';
import GeoJsonFileControl from '@/utils/geoJsonFileControl';
import RulerControl from '@/utils/rulerControl';
import MAP_SETTINGS from '@/constants/mapSettings';
import MapDataHelper from '@/utils/mapDataHelper';
import AuthHelper from '@/utils/authHelper';

export default {
  name: 'Map',
  data() {
    return {
      mapboxHelper: null,
      spotInfoFeatures: [],
      pendingJumpTo: null,
      pendingSourceLayersVisibility: {},
      mapSettings: MAP_SETTINGS,
    };
  },
  created() {
    eventBus.$on(events.jumpToRequested, this.jumpTo);
    eventBus.$on(events.refreshNewFeaturesRequested, this.refreshNewFeatures);
    eventBus.$on(events.editFeatureRequested, this.startEditingFeature);
    eventBus.$on(events.setSourceLayersVisible, this.setSourceLayersVisible);
  },
  mounted() {
    this.mapboxHelper = new MapboxHelper('map', this.mapSettings);
    this.mapboxHelper.setOnMapReadyListener(this.onMapReady);
    this.mapboxHelper.setOnMapClickListener(this.onMapClick);
    this.mapboxHelper.setOnMapCtrlClickListener(this.onMapCtrlClick);
    this.mapboxHelper.setOnMapDoubleClickListener(this.onMapDoubleClick);
    this.mapboxHelper.setOnIdleListener(this.onMapIdle);
    this.mapboxHelper.setDrawListeners(this.onDrawModeChange, this.onDraw);
    this.mapboxHelper.addControl(SatelliteLayerControl);
    this.mapboxHelper.addControl(StreetViewControl);
    this.mapboxHelper.addControl(new RulerControl(
      this.mapboxHelper,
      (m) => {
        this.$alertDialog.show({
          title: 'Measure',
          message: `The geometry length is ${m.toFixed(2)} meters.`,
        });
      },
    ));
    this.mapboxHelper.addControl(new GeoJsonFileControl(this.mapSettings.sources.uploadedFeatures.url));
    this.mapboxHelper.addControl(SettingsControl);
    this.mapboxHelper.map.on(
      'load',
      async () => {
        await this.mapboxHelper.loadMapillary();
        this.updateMapillaryFeaturesVisibility();
      },
    );
  },
  computed: {
    ...mapGetters('editor', [
      'selection',
      'spotInfo',
      'fuelStation',
      'poi',
      'searchedPlace',
      'temporaryFeatureDraw',
      'areasToMap',
      'streetViewEnabled',
      'streetViewCamera',
      'deletedSpotIds',
      'deletedFuelStationIds',
      'featureChanges',
      'onMapClickListener',
      'baseMap',
      'mapFeatures',
    ]),
  },
  watch: {
    baseMap(value) {
      this.mapboxHelper.map.setLayoutProperty(
        'mapbox-satellite',
        'visibility',
        value === 'Satellite' ? 'visible' : 'none',
      );
    },
    mapFeatures() {
      this.updateMapillaryFeaturesVisibility();
    },
    selection(selection) {
      if (selection.id === null || (selection.features.length > 0 && MapboxHelper.isEditedFeature(selection.features[0]))) {
        this.setSpotInfoFeatures(null);
      }
    },
    spotInfo(spotInfo) {
      if (spotInfo !== null) {
        this.checkPendingJumpTo();
        this.checkMissingSpotInfoFeatures();
      }
    },
    fuelStation(fuelStation) {
      if (fuelStation !== null) {
        this.checkPendingJumpTo();
        this.checkMissingSpotInfoFeatures();
      }
    },
    poi(poi) {
      if (poi !== null) {
        this.checkPendingJumpTo();
        this.checkMissingSpotInfoFeatures();
      }
    },
    searchedPlace(searchedPlace) {
      const features = [];
      if (searchedPlace.lngLat !== null) {
        this.jumpTo(searchedPlace.lngLat);
        features.push(MapboxHelper.newPoint({ lng: searchedPlace.lngLat[0], lat: searchedPlace.lngLat[1] }, { address: searchedPlace.address }));
      }

      if (this.mapboxHelper.isReady) {
        this.mapboxHelper.setSourceFeatures(this.mapSettings.sources.searchedPlace.url, features);
      }
    },
    temporaryFeatureDraw(temporaryFeatureDraw, prevTemporaryFeatureDraw) {
      if (temporaryFeatureDraw !== null && prevTemporaryFeatureDraw !== temporaryFeatureDraw) {
        this.$store.dispatch('editor/selection', null);
        this.mapboxHelper.drawTemporaryFeature(temporaryFeatureDraw.type, (e) => temporaryFeatureDraw.onFeatureDrawn(e.features[0]));
      } else if (temporaryFeatureDraw === null && prevTemporaryFeatureDraw !== null) {
        this.mapboxHelper.stopDrawingTemporaryFeature();
      }
    },
    areasToMap(areasToMap) {
      if (this.mapboxHelper.isReady) {
        this.mapboxHelper.setSourceFeatures(this.mapSettings.sources.areasToMap.url, areasToMap.features);
      }
    },
    streetViewEnabled(streetViewEnabled) {
      if (!streetViewEnabled) {
        this.mapboxHelper.setSourceFeatures(this.mapSettings.sources.svPin.url, []);
      }
    },
    streetViewCamera(streetViewCamera) {
      if (streetViewCamera.position === null) {
        return;
      }
      this.mapboxHelper.setSourceFeatures(this.mapSettings.sources.svPin.url, [
        MapboxHelper.newPoint(streetViewCamera.position, { heading: streetViewCamera.heading }),
      ]);
    },
    deletedSpotIds() {
      this.updateRegulationsFilter();
    },
    deletedFuelStationIds() {
      this.updateFuelStationsFilter();
    },
    featureChanges(featureChanges, prevFeatureChanges) {
      const deleted = Object.keys(prevFeatureChanges).filter((id) => !(id in featureChanges));
      for (const id of deleted) {
        this.mapboxHelper.deleteDrawnFeature(id);
      }

      const added = Object.keys(featureChanges).filter((id) => !(id in prevFeatureChanges));
      for (const id of added) {
        this.mapboxHelper.addDrawnFeature(featureChanges[id].feature);
      }

      if (deleted.length > 0) {
        this.refreshFeatures();
        this.refreshNewFeatures();
      }

      if (added.length > 0 && featureChanges[added[added.length - 1]].originalFeature !== null) {
        this.onEditedFeatureClick(featureChanges[added[added.length - 1]].feature);
      }

      if (deleted.length > 0 || added.length > 0) {
        this.updateRegulationsFilter();
      }
    },
    onMapClickListener(onMapClickListener) {
      this.mapboxHelper.setCustomCursor(onMapClickListener !== null ? 'crosshair' : null);
    },
  },
  methods: {
    updateMapillaryFeaturesVisibility() {
      const mapping = {
        'mapillary-parking-sign': 'Parking signs',
        'mapillary-bus-stop': 'Bus stops',
        'mapillary-fire-hydrant': 'Fire hydrants',
        'mapillary-parking-meter': 'Parking meters',
        'mapillary-coverage': 'Street imagery trace',
      };
      for (const [layer, setting] of Object.entries(mapping)) {
        this.mapboxHelper.map.setLayoutProperty(
          layer,
          'visibility',
          this.mapFeatures[setting] ? 'visible' : 'none',
        );
      }
    },
    resize() {
      this.mapboxHelper.resize();
    },
    refreshFeatures() {
      this.mapboxHelper.refreshSource('regulations');
    },
    refreshNewFeatures() {
      this.mapboxHelper.refreshSource('newRegulations');
    },
    onMapReady() {
      this.mapboxHelper.setSourceLayersVisible(this.mapSettings.sources.pois.url, AuthHelper.getUser().role.map_data_edit_pois);
      this.mapboxHelper.setSourceFeatures(this.mapSettings.sources.areasToMap.url, this.areasToMap.features);
      this.checkPendingJumpTo();
      this.checkMissingSpotInfoFeatures();
      for (const [sourceId, visible] of Object.entries(this.pendingSourceLayersVisibility)) {
        this.mapboxHelper.setSourceLayersVisible(sourceId, visible);
      }
    },
    onMapClick(lngLat, feature) {
      if (this.onMapClickListener !== null) {
        return this.onMapClickListener(lngLat, feature);
      }

      if (feature === null) {
        if (this.selection.id in this.featureChanges && !MapDataHelper.hasFeatureChanged(this.featureChanges[this.selection.id])) {
          this.$store.dispatch('editor/featureSaved', this.selection.id);
        }
        this.$store.dispatch('editor/selection', null);
      } else if (feature.layer.source === this.mapSettings.sources.cities.url) {
        this.onCityClick(feature);
      } else if (feature.properties && feature.properties.type === 'fuel_station') {
        this.onFuelStationClick(feature);
      } else if (feature.properties && feature.properties.type === 'poi') {
        this.onPoiClick(feature);
      } else if (feature.layer.source === this.mapSettings.sources.uploadedFeatures.url) {
        this.onUploadedFeatureClick(lngLat, feature);
      } else if (MapboxHelper.isEditedFeature(feature)) {
        this.onEditedFeatureClick(feature);
      } else {
        this.onRegulationsFeatureClick(feature);
      }

      return false;
    },
    onCityClick(feature) {
      this.jumpTo([feature.properties.lng, feature.properties.lat], 14.0);
    },
    onFuelStationClick(feature) {
      this.setSpotInfoFeatures([feature]);
      this.$store.dispatch('editor/selection', {
        id: feature.id,
        type: 'FuelStation',
        features: [feature],
      });
    },
    onPoiClick(feature) {
      this.setSpotInfoFeatures([feature]);
      this.$store.dispatch('editor/selection', {
        id: feature.id,
        type: 'Poi',
        features: [feature],
      });
    },
    onUploadedFeatureClick(lngLat, feature) {
      this.mapboxHelper.showPopup(
        feature.geometry.type === 'Polygon' ? lngLat : turf.center(feature).geometry.coordinates,
        `${Object.entries(feature.properties).map(([k, v]) => `${k}: ${v}`).join('<br>')}`,
      );
    },
    onEditedFeatureClick(feature) {
      feature.id = feature.id || feature.properties.id; // For some reason the id may not be set at the root of the feature when queried from the map
      this.mapboxHelper.setDrawnFeatureSelected(feature);
      this.$store.dispatch('editor/selection', {
        id: feature.id,
        type: feature.properties.type || 'Regulation',
        garage: MapDataHelper.isGarage(feature),
        features: [feature],
      });
    },
    onRegulationsFeatureClick(feature) {
      let features;
      if (feature.geometry.type === 'Point') {
        features = this.mapboxHelper.querySourceFeatures(feature.source, 'segments', ['==', ['id'], feature.id]);
      } else {
        features = this.mapboxHelper.querySourceFeatures(feature.source, 'centroids', ['==', ['id'], feature.id]);
        if (features.length === 0) {
          features = [turf.center(feature.toJSON(), { properties: feature.properties, id: feature.id })];
        }
      }

      features.push(feature);
      this.setSpotInfoFeatures(features);

      this.$store.dispatch('editor/selection', {
        id: feature.id,
        type: feature.properties.type || 'Regulation',
        garage: feature.properties.name === 'Garage',
        features,
      });
    },
    onMapCtrlClick(lngLat) {
      if (this.streetViewEnabled) {
        this.$store.dispatch('editor/streetViewPositionClick', lngLat);
      }
    },
    onMapDoubleClick() {
      if (this.onMapClickListener !== null) return true;

      if (this.selection.id !== null && this.selection.type === 'Regulation') {
        this.startEditingFeature();
        return true;
      }

      return false;
    },
    onMapIdle(center, zoom) {
      eventBus.$emit(events.cameraIdle, center, zoom);
    },
    onDrawModeChange(mode) {
      // Need to enable/disable double click zoom after a timeout as Mapbox Draw overrides it somehow
      if (mode === 'simple_select') {
        setTimeout(() => this.mapboxHelper.enableDoubleClickZoom(), 300);
        this.$store.dispatch('editor/temporaryFeatureDraw', null);
      } else {
        setTimeout(() => this.mapboxHelper.disableDoubleClickZoom(), 300);
        this.$store.dispatch('editor/selection', null);
      }
    },
    onDraw(event) {
      if (event.type === 'draw.create') {
        this.$store.dispatch('editor/addDrawnFeature', event.features[0]);
      } else if (this.mapboxHelper.isEditing() && event.type === 'draw.update') {
        this.$store.dispatch('editor/updateFeatureChange', event.features[0]);
      }
    },
    jumpTo(target, zoom = 16.0) {
      if (Array.isArray(target)) {
        this.mapboxHelper.jumpTo(target, zoom);
      } else if (Object.prototype.hasOwnProperty.call(target, 'type')) {
        this.jumpToItem(null, target, zoom);
      } else if (this.selection.type === 'Regulation') {
        this.jumpToItem(this.spotInfo?.status, target, zoom);
      } else if (this.selection.type === 'FuelStation') {
        this.jumpToItem(this.fuelStation, target, zoom);
      } else if (this.selection.type === 'Poi') {
        this.jumpToItem(this.poi, target, zoom);
      }
    },
    jumpToItem(item, target, zoom) {
      if (item?.id === target && this.mapboxHelper.isReady) {
        this.mapboxHelper.jumpTo([item.lng, item.lat], zoom);
      } else if (Object.prototype.hasOwnProperty.call(target, 'type')) {
        this.pendingJumpTo = {
          type: target.type,
          id: target.id,
          zoom,
        };
      } else {
        this.pendingJumpTo = {
          type: this.selection.type,
          id: target,
          zoom,
        };
      }
    },
    setSourceLayersVisible(sourceId, visible) {
      if (this.mapboxHelper !== null && this.mapboxHelper.isReady) {
        this.mapboxHelper.setSourceLayersVisible(sourceId, visible);
      } else {
        this.pendingSourceLayersVisibility[sourceId] = visible;
      }
    },
    setSpotInfoFeatures(features) {
      this.spotInfoFeatures = features || [];
      this.mapboxHelper.setSourceFeatures(this.mapSettings.sources.spotInfo.url, features);
    },
    startEditingFeature() {
      const feature = this.spotInfoFeatures.find((f) => f.geometry.type === 'LineString');
      if (!feature) throw new Error('requested feature editing but no linestring spot info feature available');

      this.$store.dispatch('editor/addFeatureChange', feature);
      this.setSpotInfoFeatures(null);
      this.updateRegulationsFilter();
    },
    updateRegulationsFilter() {
      if (!this.mapboxHelper.isReady) {
        return;
      }

      const idsToHide = this.deletedSpotIds.concat(
        Object.values(this.featureChanges).filter((c) => c.originalFeature !== null).map((c) => c.originalFeature.id),
      );

      let filter = null;
      if (idsToHide.length > 0) {
        filter = ['!', ['in', ['id'], ['literal', idsToHide]]];
      }
      this.mapboxHelper.setSourceLayersFilter(this.mapSettings.sources.regulations.url, filter);
    },
    updateFuelStationsFilter() {
      if (!this.mapboxHelper.isReady) {
        return;
      }

      let filter = null;
      if (this.deletedFuelStationIds.length > 0) {
        filter = ['!', ['in', ['id'], ['literal', this.deletedFuelStationIds]]];
      }
      this.mapboxHelper.setSourceLayersFilter(this.mapSettings.sources.fuelStations.url, filter);
    },
    checkPendingJumpTo() {
      if (this.pendingJumpTo === null || !this.mapboxHelper.isReady) {
        return;
      }

      switch (this.pendingJumpTo.type) {
        case 'Regulation':
          if (this.spotInfo !== null && this.spotInfo.status.id === this.pendingJumpTo.id) {
            this.mapboxHelper.jumpTo([this.spotInfo.status.lng, this.spotInfo.status.lat], this.pendingJumpTo.zoom);
            this.pendingJumpTo = null;
          }
          break;
        case 'FuelStation':
          if (this.fuelStation !== null && this.fuelStation.id === this.pendingJumpTo.id) {
            this.mapboxHelper.jumpTo([this.fuelStation.lng, this.fuelStation.lat], this.pendingJumpTo.zoom);
            this.pendingJumpTo = null;
          }
          break;
        case 'Poi':
          if (this.poi !== null && this.poi.id === this.pendingJumpTo.id) {
            this.mapboxHelper.jumpTo([this.poi.lng, this.poi.lat], this.pendingJumpTo.zoom);
            this.pendingJumpTo = null;
          }
          break;
        default:
          break;
      }
    },
    checkMissingSpotInfoFeatures() {
      if (!this.mapboxHelper.isReady || this.selection.type === null || this.spotInfo === null) {
        return;
      }

      const features = [];
      switch (this.selection.type) {
        case 'Regulation': {
          features.push(turf.point(
            [this.spotInfo.status.lng, this.spotInfo.status.lat],
            {
              name: this.spotInfo.status.name,
              garage_name: this.spotInfo.status.garage_name || this.spotInfo.status.garage_type,
              customer_garage: this.spotInfo.status.customer_garage === true,
            },
            { id: this.spotInfo.status.id },
          ));
          features.push(
            ...this.mapboxHelper.querySourceFeatures(
              this.mapSettings.sources.spotInfo.url,
              'centroids',
              [
                'all',
                ['==', ['id'], this.spotInfo.status.id],
                ['!=', ['geometry-type'], 'Point'],
              ],
            ).filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)),
          );

          for (const entrance of (this.spotInfo.status.entrances || [])) {
            features.push(turf.point(
              [entrance.lng, entrance.lat],
              {
                type: 'entrance',
                address: entrance.address,
                bearing: turf.bearing(
                  turf.point([entrance.lng, entrance.lat]),
                  turf.point([this.spotInfo.status.lng, this.spotInfo.status.lat]),
                ),
              },
            ));
          }
          break;
        }
        case 'FuelStation':
          features.push(turf.point(
            [this.fuelStation.lng, this.fuelStation.lat],
            {
              type: 'fuel_station',
              label: this.fuelStation.name,
              has_gas: this.fuelStation.has_gas,
              has_ev_charging: this.fuelStation.has_ev_charging,
            },
            { id: this.fuelStation.id },
          ));
          break;
        case 'Poi':
          features.push(turf.point(
            [this.poi.lng, this.poi.lat],
            {
              type: 'poi',
              name: this.poi.name,
            },
            { id: this.poi.id },
          ));
          break;
        default:
          return;
      }

      this.setSpotInfoFeatures(features);
    },
  },
};
</script>

<style scoped>

#map {
  width: calc(100% - 375px);
  height: 100%;
  left: 375px;
}

</style>

<!--suppress CssUnusedSymbol -->
<style>

.mapboxgl-canvas {
  outline: none;
}

.mapboxgl-custom-ctrl-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  font-size: 12px;
}

.mapboxgl-custom-ctrl-icon.disabled {
  opacity: 0.3;
}

.mapboxgl-custom-ctrl-icon-fuel-stations {
  background-image: url('~@/assets/images/gas-station-black-24dp.svg');
}

.mapboxgl-custom-ctrl-icon-layers {
  background-image: url('~@/assets/images/layers.svg');
}

.mapboxgl-custom-ctrl-icon-ruler {
  background-image: url('~@/assets/images/ruler.svg');
}

.mapboxgl-custom-ctrl-icon-sv {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTI4IDEyOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48c3R5bGU+PC9zdHlsZT48ZyBpZD0iX3gzMV8wX1ZpcnR1YWxfcmVhbGl0eSI+PHBhdGggZD0iTTY0IDI0QzM2LjkgMjQgOCAzMi40IDggNDh2NDBjMCAxNS42IDI4LjkgMjQgNTYgMjRzNTYtOC40IDU2LTI0VjQ4YzAtMTUuNi0yOC45LTI0LTU2LTI0em0wIDhjMjkuNyAwIDQ4IDkuMyA0OCAxNiAwIDQuMS02LjcgOC44LTE3LjIgMTEuOWwtMi44LjlWNjdjLTMuNy0uOS03LjctMS42LTEyLTIuMVY2NGMwLTguOC03LjItMTYtMTYtMTZzLTE2IDcuMi0xNiAxNnYuOWMtNC4zLjUtOC4zIDEuMi0xMiAyLjF2LTYuMmwtMi44LS45QzIyLjcgNTYuOCAxNiA1Mi4xIDE2IDQ4YzAtNi43IDE4LjMtMTYgNDgtMTZ6bTQ4IDI4Ljh2MTQuM2MtMy4yLTIuMy03LjItNC4yLTEyLTUuOXYtMi42YzQuOC0xLjYgOC45LTMuNSAxMi01Ljh6TTU2IDY0YzAtNC40IDMuNi04IDgtOHM4IDMuNiA4IDgtMy42IDgtOCA4LTgtMy42LTgtOHptLTI4IDUuM2MtNC44IDEuNi04LjggMy42LTEyIDUuOVY2MC44YzMuMSAyLjIgNy4yIDQuMiAxMiA1Ljh2Mi43ek0xNiA4OGMwLTMuMyA0LjMtNy4xIDEyLTEwLjF2MjAuM2MtNy43LTMuMS0xMi03LTEyLTEwLjJ6bTIwIDEyLjhWNzUuM2M0LjMtMS4xIDkuMi0yIDE0LjYtMi42LjUuOCAxLjEgMS42IDEuOCAyLjNDNDUgNzkuMSA0MCA4NyA0MCA5Nmg4YzAtOC44IDcuMi0xNiAxNi0xNnMxNiA3LjIgMTYgMTZoOGMwLTktNS0xNi45LTEyLjQtMjEgLjctLjcgMS4zLTEuNSAxLjgtMi4zIDUuNC42IDEwLjMgMS41IDE0LjYgMi42djI1LjVjLTcuNSAyLTE3IDMuMi0yOCAzLjJzLTIwLjUtMS4zLTI4LTMuMnptNjQtMi42Vjc3LjljNy43IDMuMSAxMiA2LjkgMTIgMTAuMSAwIDMuMi00LjMgNy4xLTEyIDEwLjJ6Ii8+PGNpcmNsZSBjeD0iNDQiIGN5PSI0OCIgcj0iNCIvPjwvZz48L3N2Zz4=);
}

.mapboxgl-custom-ctrl-icon-settings {
  background-image: url('~@/assets/images/settings.svg');
}

.mapboxgl-custom-ctrl-icon-upload {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMCAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEzLjI5MjkgNS43MDcwOUMxMy42ODM0IDYuMDk3NjMgMTQuMzE2NiA2LjA5NzYzIDE0LjcwNzEgNS43MDcwOUMxNS4wOTc2IDUuMzE2NTkgMTUuMDk3NiA0LjY4MzQxIDE0LjcwNzEgNC4yOTI5MUwxMC43MTExIDAuMjk2OTM2QzEwLjcwOTggMC4yOTU1OTMgMTAuNzA4NCAwLjI5NDI1IDEwLjcwNzEgMC4yOTI5MDhDMTAuNTExOCAwLjA5NzY1NjIgMTAuMjU1OSAwIDEwIDBDOS43NDQwOCAwIDkuNDg4MTYgMC4wOTc2NTYyIDkuMjkyOTEgMC4yOTI5MDhDOS4yOTE1NiAwLjI5NDI1IDkuMjkwMjIgMC4yOTU1OTMgOS4yODg4OCAwLjI5NjkzNkw1LjI5MjkxIDQuMjkyOTFDNC45MDIzNyA0LjY4MzQxIDQuOTAyMzcgNS4zMTY1OSA1LjI5MjkxIDUuNzA3MDlDNS42ODM0MSA2LjA5NzYzIDYuMzE2NTkgNi4wOTc2MyA2LjcwNzA5IDUuNzA3MDlMOSAzLjQxNDIyVjE1QzkgMTUuNTUyMyA5LjQ0NzcyIDE2IDEwIDE2QzEwLjU1MjMgMTYgMTEgMTUuNTUyMyAxMSAxNVYzLjQxNDIyTDEzLjI5MjkgNS43MDcwOVpNMCAxNlYxOUMwIDIwLjY1NjkgMS4zNDMxNCAyMiAzIDIySDE3QzE4LjY1NjkgMjIgMjAgMjAuNjU2OSAyMCAxOVYxNkMyMCAxNS40NDc3IDE5LjU1MjMgMTUgMTkgMTVDMTguNDQ3NyAxNSAxOCAxNS40NDc3IDE4IDE2VjE5QzE4IDE5LjU1MjMgMTcuNTUyMyAyMCAxNyAyMEgzQzIuNDQ3NzIgMjAgMiAxOS41NTIzIDIgMTlWMTZDMiAxNS40NDc3IDEuNTUyMjggMTUgMSAxNUMwLjQ0NzcyMyAxNSAwIDE1LjQ0NzcgMCAxNloiIGZpbGw9IiMzMzMzMzMiLz48L3N2Zz4K);
}

</style>
