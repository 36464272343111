<template>
  <div id="spot-sheet">
    <template v-if="editing">
      <EditGarageSheet v-if="selection.garage" :initial-spot-data="spotData" :spot-info="spotInfo" :garage-details="garageDetails" v-on:cancel="editing = false" v-on:updated="onSpotDataUpdated"/>
      <EditOnStreetSheet v-else :initial-spot-data="spotData" :title="spotInfo !== null ? spotInfo.status.address : 'New segment'" v-on:cancel="editing = false" v-on:updated="onSpotDataUpdated"/>
    </template>
    <template v-else-if="spotInfo === null || spotData === null">
      <SheetTitle title="..."/>
    </template>
    <template v-else>
      <SheetTitle
        :title="loading || error ? '' : (spotInfo.status.garage_name || spotInfo.status.garage_type || spotInfo.status.address)"
        back-button="close"
        v-on:back-clicked="$store.dispatch('editor/selection', {})"
        action="Edit"
        v-on:action-clicked="editing = true"
      />
      <template v-if="error">
        <SheetCard>
          <SheetCardRow id="error-spot-info">
            <h1>Server returned {{ error }}</h1>
            <span v-if="error === 404">This spot does not exist</span>
            <MaterialButton label="Retry" v-on:click="loadSpotInfo()"/>
          </SheetCardRow>
        </SheetCard>
      </template>
      <template v-else-if="!loading">
        <SheetCard>
          <SheetCardRow v-if="spotInfo.pictures.length > 0">
            <Pictures :data="spotInfo.pictures"/>
          </SheetCardRow>
          <SpotRegulations :spotInfo="spotInfo"/>
          <SheetCardRow v-if="Object.prototype.hasOwnProperty.call(spotsToCheck, spotInfo.status.id)">
            <div class="spot-needs">
              <img :src="require('@/assets/images/report_black_24dp.svg')" alt="Close modal"/>
              <span>{{ spotToCheckNeeds(spotsToCheck[spotInfo.status.id]) }}</span>
            </div>
          </SheetCardRow>
          <SheetTitle v-if="spotData.garage_rates.length > 0" :title="`Rates (${spotData.garage_rates.length})`"/>
          <GaragePriceInfo v-if="spotData.garage_rates.length > 0" :value="spotInfo !== null && spotInfo.garage_info !== null ? spotInfo.garage_info.find((i) => i.type === 0) : null"/>
          <SheetTitle v-if="spotData.garage_deals.length > 0" :title="`Deals (${spotData.garage_deals.length})`"/>
          <GaragePriceInfo v-if="spotData.garage_deals.length > 0" :value="spotInfo !== null && spotInfo.garage_info !== null ? spotInfo.garage_info.find((i) => i.type === 1) : null"/>
          <SheetTitle v-if="spotData.garage_monthly_rates.length > 0" :title="`Monthly rates (${spotData.garage_monthly_rates.length})`"/>
          <SheetCardRow v-if="spotData.garage_monthly_rates.length > 0">
              <span v-for="(rate, i) in spotData.garage_monthly_rates" :key="i">
                {{ (monthlyTypes.find(t => t.id === rate.monthly_type_id) || { label: rate.monthly_type_id }).label }} - ${{ rate.price }}
              </span>
          </SheetCardRow>
        </SheetCard>
        <SheetCard>
          <SheetCardRow>
            <span>
              ID:
              {{ spotInfo.status.id }}
              <span
                class="copy-segment-id"
                v-on:click="copySegmentId"
              >
                COPY ⎘
              </span>
            </span>
            <span class="details">Coordinates: <span>{{ spotInfo.status.lng.toFixed(7) }}, {{ spotInfo.status.lat.toFixed(7) }}</span></span>
            <template v-if="garageDetails !== null">
              <span class="details">Bookable in transient: {{ garageDetails.payment_enabled ? 'Yes' : 'No' }}</span>
              <span class="details">Bookable in monthly: {{ garageDetails.monthly_payment_enabled ? 'Yes' : 'No' }}</span>
              <span class="details">HQ email: <span>{{ garageDetails.spoc_email || 'none' }}</span></span>
            </template>
          </SheetCardRow>
          <input type="file" hidden="hidden" multiple="multiple" ref="photoInput" v-on:change="onPhotosSelected">
          <SheetCardAction v-if="spotInfo.status.id !== 0" :icon="require('@/assets/images/add_a_photo-blue-24dp.svg')" action="Add photos" v-on:click="this.selectPhotos"/>
          <SheetCardAction v-if="spotInfo.status.id !== 0" :icon="require('@/assets/images/edit-blue-16dp.svg')" action="Edit photos" v-on:click="this.startPicturesEditing"/>
          <SheetCardAction v-if="spotInfo.status.id !== 0" :icon="require('@/assets/images/check-blue-16dp.svg')" action="Mark as accurate" :summary="spotInfo.crowdsource.accuracy_status" v-on:click="this.markAsAccurate" :disabled="markingAsAccurate"/>
          <SheetCardAction :icon="require('@/assets/images/location_on-blue-18dp.svg')" action="Go to spot" v-on:click="this.jumpToSpot"/>
          <SheetCardAction :icon="require('@/assets/images/edit-blue-16dp.svg')" action="Edit feature" v-on:click="this.editFeature"/>
          <SheetCardAction :icon="require('@/assets/images/delete-red-24dp.svg')" color="#ff4541" action="Delete spot" v-on:click="this.showDeleteSpotModal" :disabled="spotInfo.status.name === 'Garage' && garageDetails === null"/>
        </SheetCard>
        <ChangesetList ref="changesetList"/>
        <Modal
          ref="deleteModal"
          title="Delete spot"
          negative-action="Keep spot"
          v-on:negative-action="$refs.deleteModal.closeModal()"
          positive-action="Delete spot"
          v-on:positive-action="doDeleteSpot"
          :positive-action-disabled="garageDetails !== null && (garageDetails.payment_enabled || garageDetails.monthly_payment_enabled)"
        >
          <template v-slot:body v-if="garageDetails !== null && (garageDetails.payment_enabled || garageDetails.monthly_payment_enabled)">
            This garage is bookable and cannot be deleted
          </template>
          <template v-slot:body v-else>
            Delete spot #{{ spotInfo.status.id }} at {{ spotInfo.status.address }}?
          </template>
        </Modal>
        <Modal
          ref="editPicturesModal"
          title="Edit photos"
          negative-action="Cancel"
          v-on:negative-action="cancelPicturesEditing"
          positive-action="Save"
          v-on:positive-action="savePicturesOrder"
          :positive-action-disabled="isSavingPicturesOrder"
        >
          <template v-slot:body>
            <Checkbox label="Monthly order" v-model="reorderMonthly" :disabled="spotInfo.status.name !== 'Garage'"/>
            <draggable v-model="reorderedPictures" class="draggable-container" handle=".draggable-handle">
              <div v-for="picture in reorderedPictures" :key="picture.thumbnail_url" class="draggable-item">
                <img class="draggable-picture" :src="picture.thumbnail_url" alt="Spot picture">
                <ImageButton class="draggable-delete" title="Delete" alt="Delete" :src="require('@/assets/images/close-white-24dp.svg')" v-on:click="askDeletePicture(picture)"/>
                <img class="draggable-handle" :src="require('@/assets/images/drag_handle_black_24dp.svg')" alt="Drag handle">
              </div>
            </draggable>
          </template>
        </Modal>
      </template>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import ApiHelper from '@/utils/apiHelper';
import AuthHelper from '@/utils/authHelper';
import ChangesetList from '@/components/editing/ChangesetList';
import Checkbox from '@/components/form/Checkbox';
import EditGarageSheet from '@/components/editing/EditGarageSheet';
import EditOnStreetSheet from '@/components/editing/EditOnStreetSheet';
import { eventBus, events } from '@/constants/eventBus';
import GaragePriceInfo from '@/components/editing/GaragePriceInfo';
import ImageButton from '@/components/ImageButton';
import MapDataHelper from '@/utils/mapDataHelper';
import MaterialButton from '@/components/MaterialButton';
import Modal from '@/components/Modal';
import Pictures from '@/components/Pictures';
import RegulationsHelper from '@/utils/regulationsHelper';
import SheetCard from '@/components/SheetCard';
import SheetCardAction from '@/components/SheetCardAction';
import SheetCardRow from '@/components/SheetCardRow';
import SheetTitle from '@/components/SheetTitle';
import SpotRegulations from '@/components/SpotRegulations';
import Toast from '@/utils/toast';

export default {
  name: 'SpotSheet',
  components: {
    ChangesetList,
    Checkbox,
    draggable,
    EditGarageSheet,
    EditOnStreetSheet,
    GaragePriceInfo,
    ImageButton,
    MaterialButton,
    Modal,
    Pictures,
    SheetCard,
    SheetCardAction,
    SheetCardRow,
    SheetTitle,
    SpotRegulations,
  },
  data() {
    return {
      user: null,
      loading: false,
      error: null,
      editing: false,
      markingAsAccurate: false,
      spotData: null,
      garageDetails: null,
      reorderMonthly: false,
      reorderedPicturesTransient: null,
      reorderedPicturesMonthly: null,
      isSavingPicturesOrder: false,
      pendingDeletes: {},
      monthlyTypes: [],
    };
  },
  computed: {
    ...mapGetters('editor', [
      'selection',
      'spotInfo',
      'pendingSpotData',
      'featureChanges',
      'changesetHistory',
      'spotsToCheck',
    ]),
    reorderedPictures: {
      get() {
        return this.reorderMonthly ? this.reorderedPicturesMonthly : this.reorderedPicturesTransient;
      },
      set(reorderedPictures) {
        if (this.reorderMonthly) {
          this.reorderedPicturesMonthly = reorderedPictures;
        } else {
          this.reorderedPicturesTransient = reorderedPictures;
        }
      },
    },
  },
  watch: {
    selection(selection, prevSelection) {
      if (
        (prevSelection.type !== 'Regulation')
        || (selection.id !== prevSelection.id && (selection.tmpId === null || selection.tmpId !== prevSelection.id))
      ) {
        this.editing = selection.id in this.featureChanges || selection.id in this.pendingSpotData;
        this.loadSpotInfo();
        this.loadSpotData();
      } else if (selection.garage && !prevSelection.garage) {
        MapDataHelper.getGarageDetails(selection.id, this.onGotGarageDetails, this.onGarageDetailsError);
      }
    },
    featureChanges(featureChanges) {
      if (!this.editing && this.selection.id in featureChanges) {
        this.editing = true;
      }
    },
    changesetHistory() {
      if (this.$refs.changesetList && this.$el.scrollTop > this.$refs.changesetList.$el.offsetTop) {
        this.$el.scrollTop = this.$refs.changesetList.$el.offsetTop;
      }
    },
  },
  created() {
    eventBus.$on(events.changesetRestored, () => {
      this.loadSpotInfo();
      this.loadSpotData();
    });

    MapDataHelper.getMonthlyTypes((request, response) => { this.monthlyTypes = response.monthly_types; }, () => Toast.danger('Error loading monthly types'));
  },
  mounted() {
    this.user = AuthHelper.getUser();
    this.editing = this.selection.id in this.featureChanges || this.selection.id in this.pendingSpotData;
    this.loadSpotInfo();
    this.loadSpotData();
  },
  methods: {
    loadSpotInfo() {
      this.error = null;

      this.$store.dispatch('editor/spotInfo', null);
      this.garageDetails = null;

      if (this.selection.id == null || typeof this.selection.id === 'string') return;

      this.loading = !this.editing;
      RegulationsHelper.getSpotInfo(this.selection.id, null, this.onGotSpotInfo, this.onSpotInfoError);

      if (this.selection.garage) {
        MapDataHelper.getGarageDetails(this.selection.id, this.onGotGarageDetails, this.onGarageDetailsError);
      }
    },
    onGotSpotInfo(request, result) {
      if (this.selection.type !== 'Regulation' || this.selection.id !== result.status.id) {
        return;
      }

      this.$store.dispatch('editor/spotInfo', result);
      if (this.selection.garage === null) {
        this.$store.dispatch('editor/selection', {
          ...this.selection,
          garage: result.status.name === 'Garage',
        });
      }

      this.loading = false;
    },
    onSpotInfoError(request, response) {
      this.error = response.status;
      this.loading = false;
    },
    onGotGarageDetails(request, result) {
      this.garageDetails = result;
    },
    onGarageDetailsError() {
      Toast.danger('Error getting garage details');
    },
    loadSpotData() {
      if (this.selection.id === null) {
        this.spotData = null;
      } else if (this.selection.id in this.featureChanges && this.featureChanges[this.selection.id].originalFeature === null) {
        this.spotData = this.selection.garage ? MapDataHelper.newGarageData() : MapDataHelper.newSpotData();
      } else {
        this.spotData = null;
        MapDataHelper.getSpotData(this.selection.id, this.onGotSpotData, this.onSpotDataError);
      }
    },
    onGotSpotData(request, result) {
      this.spotData = result.data;
    },
    onSpotDataError(request, response) {
      this.error = response.status;
    },
    onSpotDataUpdated(updatedSpotData) {
      this.editing = false;
      this.spotData = updatedSpotData;
      this.loadSpotInfo();
    },
    spotToCheckNeeds(spot) {
      return MapDataHelper.spotToCheckNeeds(spot);
    },
    selectPhotos() {
      this.$refs.photoInput.click();
    },
    onPhotosSelected() {
      ApiHelper.uploadPhotos(this.selection.id, this.$refs.photoInput.files, this.loadSpotInfo);
    },
    startPicturesEditing() {
      this.reorderMonthly = this.reorderMonthly && this.spotInfo.status.name === 'Garage';
      const pictures = this.spotInfo.pictures.filter((p) => p.id !== -1);
      this.reorderedPicturesTransient = [...pictures];
      this.reorderedPicturesMonthly = [...pictures].sort((a, b) => (a.position_monthly !== undefined ? a.position_monthly : Number.MAX_SAFE_INTEGER) - (b.position_monthly !== undefined ? b.position_monthly : Number.MAX_SAFE_INTEGER));
      this.$refs.editPicturesModal.openModal();
    },
    cancelPicturesEditing() {
      this.reorderedPicturesTransient = null;
      this.reorderedPicturesMonthly = null;
      this.$refs.editPicturesModal.closeModal();
    },
    askDeletePicture(picture) {
      this.$alertDialog.show({
        title: 'Delete picture',
        message: 'Delete this picture?',
        positiveAction: {
          text: 'Delete',
          onClick: () => this.doDeletePicture(picture),
        },
        negativeAction: { text: 'Cancel' },
      });
    },
    doDeletePicture(picture) {
      this.pendingDeletes[picture.id] = picture;
      ApiHelper.updatePictureStatus(picture.id, ApiHelper.PICTURE_STATUSES.TRASH, null, null, () => this.onPictureDeleted(picture.id), () => this.onDeletePictureError(picture.id));
      this.spotInfo.pictures = this.spotInfo.pictures.filter((p) => p.id !== picture.id);
      this.reorderedPicturesTransient = this.reorderedPicturesTransient.filter((p) => p.id !== picture.id);
      this.reorderedPicturesMonthly = this.reorderedPicturesMonthly.filter((p) => p.id !== picture.id);
    },
    onPictureDeleted(pictureId) {
      delete this.pendingDeletes[pictureId];
    },
    onDeletePictureError(pictureId) {
      Toast.danger('Error deleting picture, please try again');
      this.spotInfo.pictures.push(this.pendingDeletes[pictureId]);
      this.reorderedPicturesTransient.push(this.pendingDeletes[pictureId]);
      this.reorderedPicturesMonthly.push(this.pendingDeletes[pictureId]);
      delete this.pendingDeletes[pictureId];
    },
    savePicturesOrder() {
      this.isSavingPicturesOrder = true;
      ApiHelper.savePicturesOrder(this.spotInfo.status.id, this.reorderedPicturesTransient, this.reorderedPicturesMonthly, this.onPicturesOrderSaved, this.onSavePicturesOrderError);
    },
    onPicturesOrderSaved() {
      this.spotInfo.pictures = this.reorderedPicturesTransient;
      this.reorderedPicturesTransient = null;
      this.reorderedPicturesMonthly = null;
      this.isSavingPicturesOrder = false;
      Toast.info('Pictures reordered');
      this.$refs.editPicturesModal.closeModal();
    },
    onSavePicturesOrderError() {
      this.isSavingPicturesOrder = false;
      Toast.danger('Error saving pictures order, please try again');
    },
    markAsAccurate() {
      this.markingAsAccurate = true;
      ApiHelper.markAsAccurate(this.spotInfo.status.id, this.onMarkedAsAccurate, this.onMarkAsAccurateError);
    },
    onMarkedAsAccurate(request, result) {
      eventBus.$emit(events.spotMarkedAsAccurate);
      this.spotInfo.crowdsource.needs_verification = false;
      this.spotInfo.crowdsource.accuracy_status = result.accuracy_status;
      this.markingAsAccurate = false;
      Toast.info('Spot successfully marked as accurate');
    },
    onMarkAsAccurateError() {
      this.markingAsAccurate = false;
      Toast.danger('Error marking spot as accurate, please try again');
    },
    jumpToSpot() {
      eventBus.$emit(events.jumpToRequested, [this.spotInfo.status.lng, this.spotInfo.status.lat]);
    },
    editFeature() {
      eventBus.$emit(events.editFeatureRequested);
    },
    showDeleteSpotModal() {
      this.$refs.deleteModal.openModal();
    },
    doDeleteSpot() {
      const spotId = this.spotInfo.status.id;
      MapDataHelper.deleteSpot(spotId, () => this.onSpotDeleted(spotId), this.onDeleteSpotError);
      this.$refs.deleteModal.closeModal();
    },
    onSpotDeleted(spotId) {
      this.$store.dispatch('editor/deletedSpot', spotId);
      eventBus.$emit(events.segmentDeleted, spotId);
      if (this.selection.type === 'Regulation' && this.selection.id === spotId) {
        this.$store.dispatch('editor/selection', {});
      }
      Toast.info(`Spot #${spotId} successfully deleted`);
    },
    onDeleteSpotError(request, result) {
      Toast.danger(`Error deleting spot: ${result.body.error_message}`);
    },
    async copySegmentId() {
      await navigator.clipboard.writeText(this.spotInfo.status.id);
      Toast.info(`Segment id ${this.spotInfo.status.id} copied into clipboard`);
    },
  },
};
</script>

<style scoped>

#spot-sheet {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F5F7F8;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 375px;
  scroll-behavior: smooth;
  box-shadow: 5px 0 5px -3px rgba(0, 0, 0, .25);
}

.spot-needs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spot-needs img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.spot-needs span {
  font-weight: bold;
}

span.details {
  display: flex;
}

span.details span {
  margin-left: 4px;
  float: left;
}

#error-spot-info {
  display: flex;
  flex-direction: column;
}

#error-spot-info h1 {
  margin-top: 8px;
}

#error-spot-info button {
  margin-top: 8px;
}

.draggable-container {
  max-height: 512px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  margin: 8px 0;
}

.draggable-item {
  width: 120px;
  height: 160px;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.draggable-item:first-child {
  margin-left: 0;
}

.draggable-item:last-child {
  margin-right: 0;
}

.draggable-picture {
  width: 100%;
  height: calc(100% - 36px);
}

.draggable-delete {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 16px;
  background-color: #ff454188;
}

.draggable-handle {
  width: 100%;
  height: 36px;
  cursor: grab;
  background-color: #DEDEDE;
}

.copy-segment-id {
  color: #1c9be6;
  cursor: pointer;
}

</style>
