<template>
  <LoadingScreen v-if="loadingApp || loadingError"/>
  <Layout v-else>
    <template slot="header-content">
      <Geocoder/>
      <div
        v-if="canProcessTickets"
        class="map-updates-button"
      >
        <MaterialButton
          :src="require('@/assets/images/list.svg')"
          label="Map updates"
          v-on:click="toggleUpdates"
          :disabled="false"
          color="#212529"
          :uppercase="false"
        />
      </div>
    </template>
    <template slot="body">
      <portal-target name="lightbox">
      </portal-target>
      <div class="sheet-container">
        <FuelStationSheet v-if="selection.type === 'FuelStation'"/>
        <PoiSheet v-else-if="selection.type === 'Poi'"/>
        <SpotSheet v-else-if="selection.type === 'Regulation'"/>
        <WelcomeSheet v-else/>
        <div class="side-sheet-container">
          <TicketSheet v-if="selectedTicket !== null"/>
          <ChangesetSheet v-if="selectedChangeset !== null"/>
        </div>
      </div>
      <Tiles :handleWidth="1" :tiles="tiles">
        <template v-slot:tile_0>
          <Map ref="map"/>
        </template>
        <template v-slot:tile_1>
          <Settings/>
        </template>
        <template v-slot:tile_2>
          <TicketsList v-if="canProcessTickets"/>
        </template>
        <template v-slot:tile_3>
          <StreetView
            v-if="imagerySource === 'Google Street View'"
            ref="googleStreetView"
          />
          <MapillaryStreetView
            v-else-if="imagerySource === 'Mapillary'"
            ref="mapillaryStreetView"
          />
        </template>
      </Tiles>
    </template>
  </Layout>
</template>

<script>
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import Vue from 'vue/dist/vue.esm';
import { mapGetters } from 'vuex';
import Toasted from 'vue-toasted';
import { eventBus, events } from '@/constants/eventBus';
import Layout from '@/components/Layout';
import Geocoder from '@/components/Geocoder';
import Map from '@/components/Map';
import Settings from '@/components/Settings';
import TicketsList from '@/components/editing/TicketsList';
import StreetView from '@/components/StreetView';
import MapillaryStreetView from '@/components/MapillaryStreetView';
import LoadingScreen from '@/components/LoadingScreen';
import AuthHelper from '@/utils/authHelper';
import Tiles from '@/components/Tiles';
import MaterialButton from '@/components/MaterialButton';
import ApiHelper from '@/utils/apiHelper';
import SpotSheet from '@/components/SpotSheet';
import TicketSheet from '@/components/editing/TicketSheet';
import ChangesetSheet from '@/components/editing/ChangesetSheet';
import FuelStationSheet from '@/components/editing/FuelStationSheet';
import PoiSheet from '@/components/editing/PoiSheet';
import WelcomeSheet from '@/components/editing/WelcomeSheet';

export default {
  components: {
    LoadingScreen,
    StreetView,
    MapillaryStreetView,
    Layout,
    Map,
    Settings,
    TicketsList,
    Tiles,
    Geocoder,
    MaterialButton,
    FuelStationSheet,
    PoiSheet,
    ChangesetSheet,
    SpotSheet,
    TicketSheet,
    WelcomeSheet,
  },
  data() {
    return {
      tiles: [
        {
          index: 0,
          show: true,
          onresize: () => {
            // eslint-disable-next-line no-unused-expressions
            this.$refs.map?.resize();
          },
        },
        {
          index: 1,
          show: false,
        },
        {
          index: 2,
          show: false,
        },
        {
          index: 3,
          show: false,
          onresize: () => {
            // eslint-disable-next-line no-unused-expressions
            this.$refs.mapillaryStreetView?.resize();
            // eslint-disable-next-line no-unused-expressions
            this.$refs.googleStreetView?.resize();
          },
        },
      ],
    };
  },
  created() {
    eventBus.$on(events.cameraIdle, this.onCameraIdle);
  },
  mounted() {
    Vue.use(Toasted, {
      position: 'bottom-center',
      duration: 2000,
      keepOnHover: true,
      className: 'sa-toast',
    });
  },
  computed: {
    ...mapGetters(
      'editor',
      [
        'loadingApp',
        'loadingError',
        'selection',
        'selectedTicket',
        'streetViewEnabled',
        'settingsEnabled',
        'apiReference',
        'imagerySource',
        'selection',
        'selectedChangeset',
      ],
    ),
    canProcessTickets() {
      return AuthHelper.getUser().role.map_data_validate_others;
    },
  },
  watch: {
    selection(selection) {
      switch (selection.type) {
        case 'Regulation':
          this.setSegmentIdHash(selection.id);
          break;
        case 'FuelStation':
          this.setFuelStationIdHash(selection.id);
          break;
        case 'Poi':
          this.setPoiIdHash(selection.id);
          break;
        default:
          this.clearHash();
      }
    },
    selectedTicket(selectedTicket) {
      if (selectedTicket !== null && selectedTicket.category === ApiHelper.TICKET_TYPES.GAS_PRICES_PICTURE) {
        this.setFuelStationIdHash(selectedTicket.fuel_station_id);
      } else {
        this.setSegmentIdHash(selectedTicket !== null ? selectedTicket.segment_id : null);
      }
    },
    loadingApp(loadingApp) {
      if (!loadingApp && !this.loadingError) {
        this.$nextTick(() => { this.onAppLoaded(); });
      }
    },
    streetViewEnabled(streetViewEnabled) {
      this.setTileVisibility(3, streetViewEnabled);
    },
    settingsEnabled(settingsEnabled) {
      this.setTileVisibility(1, settingsEnabled);
    },
  },
  methods: {
    onAppLoaded() {
      window.addEventListener('hashchange', this.onHashChange);
      this.onHashChange();
      if (!this.loadingError) {
        if (this.selection.id !== null) {
          eventBus.$emit(events.jumpToRequested, this.selection.id, 17.0);
          return;
        }

        const lastCamera = JSON.parse(window.localStorage.getItem('Editor.lastCamera'));
        if (lastCamera !== null) {
          eventBus.$emit(events.jumpToRequested, lastCamera.center, lastCamera.zoom);
        }
      }

      if (window.localStorage.getItem('Editor.tiles[1].show') === 'true') {
        this.setTileVisibility(1, true);
      }

      if (window.localStorage.getItem('Editor.tiles[2].show') === 'true') {
        if (AuthHelper.getUser().role.map_data_validate_others) {
          this.setTileVisibility(2, true);
        } else {
          window.localStorage.removeItem('Editor.tiles[2].show');
        }
      }
    },
    onCameraIdle(center, zoom) {
      window.localStorage.setItem('Editor.lastCamera', JSON.stringify({ center, zoom }));
    },
    setSegmentIdHash(segmentId) {
      if (Number.isInteger(segmentId)) {
        window.location.hash = `#id=${segmentId}`;
      } else {
        window.location.hash = '';
      }
    },
    setFuelStationIdHash(fuelStationId) {
      window.location.hash = `#fuel-station=${fuelStationId}`;
    },
    setPoiIdHash(poiId) {
      window.location.hash = `#poi=${poiId}`;
    },
    clearHash() {
      window.location.hash = '';
    },
    onHashChange() {
      if (this.loadingError) {
        return;
      }

      const hash = this.hashToObj();
      if ('id' in hash && (this.selection.type !== 'Regulation' || this.selection.id !== hash.id)) {
        this.$store.dispatch('editor/selection', { id: hash.id, type: 'Regulation' });
      } else if ('fuel-station' in hash && (this.selection.type !== 'FuelStation' || this.selection.id !== hash['fuel-station'])) {
        this.$store.dispatch('editor/selection', { id: hash['fuel-station'], type: 'FuelStation' });
      } else if ('poi' in hash && (this.selection.type !== 'Poi' || this.selection.id !== hash.poi)) {
        if (AuthHelper.getUser().role.map_data_edit_pois) {
          this.$store.dispatch('editor/selection', { id: hash.poi, type: 'Poi' });
        } else {
          this.clearHash();
        }
      }
    },
    hashToObj() {
      const hash = {};
      for (let i = 0; i < window.location.hash.substr(1).split('&').length; i++) {
        const kv = window.location.hash.substr(1).split('&')[i];
        const [k, v] = kv.split('=');
        hash[k] = /^\d+$/.test(v) ? parseInt(v, 10) : v;
      }

      return hash;
    },
    setTileVisibility(index, show) {
      const tiles = [...this.tiles];
      tiles[index].show = show;
      if (index !== 3) { // Don't store visibility of street view
        window.localStorage.setItem(`Editor.tiles[${index}].show`, show);
      }
      this.tiles = tiles;
    },
    toggleTileVisibility(index) {
      this.setTileVisibility(index, !this.tiles[index].show);
    },
    toggleUpdates() {
      this.toggleTileVisibility(2);
    },
  },
};
</script>

<style>

.sheet-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #212529;
}

.side-sheet-container {
  z-index: -1;
  display: inline-table;
}

.sa-toast {
  background-color: #1C9BE6 !important;
}

.sa-toast.sa-toast-danger {
  background-color: #FF4541 !important;
}

.sa-toast.sa-toast-danger > a {
  color: yellow !important;
}

.map-updates-button {
  margin-left: 16px;
}

</style>
